/* eslint-disable no-lone-blocks */
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import AppUI from '../../components/App';
import AppLoadingUI from '../../components/AppLoading';
import Home from '../Home';
import { getHistory } from '../../configureStore';
import connect from 'react-redux/es/connect/connect';
import { ConnectedRouter } from 'connected-react-router';
import {
    setAppType,
    authVkGame,
    authVkApp,
    authFbApp,
    authYandexGame,
    auth,
    setAllowBuyMoney,
    setAllowSeeAdv,
    TYPE_VK,
    TYPE_VK_APP,
    TYPE_WS,
    TYPE_FB,
    TYPE_YANDEX,
    showAdv,
    submitYandexPayment,
} from '../../store/app';
import Auth from '../Auth';

import { VK_API_VERSION, FB_SITE_APP_ID, FB_SCOPE, VK_APP_ID } from '../../config/social';
import {
    IS_PRODUCTION,
    IS_FORCED_PRODUCTION,
    SEE_ADV_PERCENT,
    ADV_PRELOADER,
    MIN_LEVEL_TO_SEE_ADV,
    VK_ADV_LIBRARY_ADMAN,
    VK_ADV_LIBRARY_ADMAN_INIT,
    YANDEX_LIB,
} from '../../config';
import { fetchProfile } from '../../store/profile';
import Socket from '../Socket';
import { getParameterByName, randomIntFromInterval, loadScript } from '../../utils';
import { getVkBridge } from '../../utils/vkBridge';

class App extends PureComponent {
    constructor(props) {
        super(props);

        this.onExternalMessage = this.onExternalMessage.bind(this);
    }

    componentDidMount() {
        this.detectAppType();
        window.addEventListener('message', this.onExternalMessage, false);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.appType && this.props.appType) {
            // We detected app type. Now we can init application
            this.startApp();

            switch (this.props.appType) {
                case TYPE_VK_APP:
                    if (this.props.isVkIos) {
                        this.props.actions.setAllowBuyMoney(false);
                    } else {
                        this.props.actions.setAllowBuyMoney(true);
                    }

                    if (this.props.isVkAndroid || this.props.isVkIos) {
                        this.props.actions.setAllowSeeAdv(true);
                    }
                    break;
                case TYPE_WS:
                    this.props.actions.setAllowBuyMoney(false);
                    break;
                case TYPE_VK:
                    if (VK_APP_ID) {
                        this.props.actions.setAllowSeeAdv(true);
                    }
                    this.props.actions.setAllowBuyMoney(true);
                    break;
                case TYPE_YANDEX:
                    this.props.actions.setAllowSeeAdv(true);
                    break;
                default:
                    this.props.actions.setAllowBuyMoney(true);
            }
        }
    }

    render() {
        let childComponent = <AppLoadingUI />;

        if (this.props.isReady) {
            childComponent = (
                <ConnectedRouter history={getHistory()}>
                    <Home />
                </ConnectedRouter>
            );
        } else {
            if (this.props.appType === TYPE_WS && !this.props.session) {
                // Render auth block
                childComponent = <Auth />;
            }
        }

        return (
            <React.Fragment>
                <Socket isActive={true} key={'socket'} appType={this.props.appType} />
                <AppUI appType={this.props.appType}>{childComponent}</AppUI>
            </React.Fragment>
        );
    }

    detectAppType() {
        if (this.props.queryAppType) {
            switch (this.props.queryAppType) {
                case TYPE_VK:
                    this.props.actions.setAppType(TYPE_VK);
                    break;
                case TYPE_FB:
                    this.props.actions.setAppType(TYPE_FB);
                    break;
                case TYPE_VK_APP:
                    this.props.actions.setAppType(TYPE_VK_APP);
                    break;
                case TYPE_YANDEX:
                    this.props.actions.setAppType(TYPE_YANDEX);
                    break;
                default:
                    this.props.actions.setAppType(TYPE_WS);
            }
        } else {
            this.props.actions.setAppType(TYPE_WS);
        }
    }

    onExternalMessage(event) {
        const data = event.data;
        const origin = event.origin;

        if (IS_PRODUCTION === true && IS_FORCED_PRODUCTION === false) {
            if (origin !== window.location.origin) {
                console.log('Incorrect origin');
                return;
            }
        }

        switch (data.event) {
            case 'auth':
                if (data.result) {
                    this.initialization();
                }
                break;
            default:
        }
    }

    startApp() {
        switch (this.props.appType) {
            case TYPE_VK:
                {
                    window.VK.addCallback('onOrderSuccess', () => {
                        this.props.actions.fetchProfile();
                    });
                }
                break;
            case TYPE_VK_APP:
                {
                    getVkBridge().subscribe((event) => {
                        switch (event.detail.type) {
                            case 'VKWebAppUpdateConfig':
                                // eslint-disable-next-line no-case-declarations
                                const schemeAttribute = document.createAttribute('scheme');

                                schemeAttribute.value = event.detail.data.scheme
                                    ? event.detail.data.scheme
                                    : 'client_light';
                                document.body.attributes.setNamedItem(schemeAttribute);
                                break;
                            case 'VKWebAppJoinGroupResult':
                                // eslint-disable-next-line no-alert
                                alert('Теперь вы будете в курсе новых событий');
                                /*if (e.detail.data.result) {
                                this.props.actions.showDialog(
                                    <Alert
                                        actions={[{
                                            title: 'Закрыть',
                                            autoclose: true,
                                            style: 'destructive'
                                        }]}
                                        onClose={() => this.props.actions.hideDialog()}
                                    >
                                        <h2>Спасибо</h2>
                                        <p>Теперь вы будете в курсе новых событий</p>
                                    </Alert>
                                );
                            } else {
                                this.props.actions.showDialog(
                                    <Alert
                                        actions={[{
                                            title: 'Закрыть',
                                            autoclose: true,
                                            style: 'destructive'
                                        }]}
                                        onClose={() => this.props.actions.hideDialog()}
                                    >
                                        <h2>Ошибка</h2>
                                        <p>Не удалось вступить в группу</p>
                                    </Alert>
                                );
                            }*/
                                break;
                            case 'VKWebAppJoinGroupFailed':
                                break;
                            default:
                                console.log(event);
                        }
                    });
                }
                break;
            case TYPE_FB:
                {
                    window.FB.init({
                        appId: FB_SITE_APP_ID,
                        autoLogAppEvents: true,
                        xfbml: false,
                        version: 'v4.0',
                    });

                    window.FB.Event.subscribe('auth.authResponseChange', () => {
                        this.initialization();
                    });
                }
                break;
            default:
        }

        this.initialization();
    }

    initialization() {
        const initResult = new Promise((resolve, reject) => {
            switch (this.props.appType) {
                case TYPE_VK: {
                    const queryAccessToken = getParameterByName('access_token', this.props.openUrl);

                    window.VK.init(
                        () => {
                            window.VK.api(
                                'users.get',
                                {
                                    fields: 'city,photo,sex,bdate,photo_max,country,home_town',
                                    lang: '0',
                                },
                                (data) => {
                                    if (data.response) {
                                        const vkUser = data.response[0];

                                        this.props.actions
                                            .authVkGame(vkUser, queryAccessToken)
                                            // eslint-disable-next-line max-nested-callbacks
                                            .then(() => {
                                                resolve();
                                            });
                                    } else {
                                        reject(new Error('Error'));
                                    }
                                }
                            );
                        },
                        () => {
                            reject(new Error('Error'));
                        },
                        VK_API_VERSION
                    );

                    break;
                }
                case TYPE_WS: {
                    this.props.actions
                        .auth()
                        .then(() => {
                            resolve();
                        })
                        .catch(() => {
                            // reject(new Error('Error'));
                        });
                    break;
                }
                case TYPE_VK_APP: {
                    console.log('Start VK connect');
                    getVkBridge()
                        .sendPromise('VKWebAppGetUserInfo')
                        .then((vkUser) => {
                            console.log('resolved');
                            this.props.actions.authVkApp(vkUser).then(
                                () => {
                                    resolve();
                                },
                                () => {}
                            );
                        })
                        .catch((error) => {
                            console.log(`${TYPE_VK_APP} Error: ${error}`);
                        });
                    break;
                }
                case TYPE_FB: {
                    window.FB.getLoginStatus((response) => {
                        if (response.status === 'connected') {
                            window.FB.api(
                                '/me',
                                'get',
                                {
                                    fields:
                                        'id,name,birthday,first_name,last_name,gender,picture.type(large),location',
                                },
                                (fbUser) => {
                                    this.props.actions
                                        .authFbApp(fbUser, response.authResponse.accessToken)
                                        .then(() => {
                                            resolve();
                                        });
                                }
                            );
                        } else if (response.status === 'not_authorized') {
                            window.FB.login(() => {}, { scope: FB_SCOPE });
                            reject(new Error('Error'));
                        } else {
                            window.FB.login(() => {}, { scope: FB_SCOPE });
                            reject(new Error('Error'));
                        }
                    });
                    break;
                }
                case TYPE_YANDEX: {
                    console.log('Start Yandex authorization');
                    document.title = `${document.title} - Яндекс`;

                    loadScript(YANDEX_LIB).then(() => {
                        window.YaGames.init().then((ysdk) => {
                            // Player get session
                            const initPlayer = () => {
                                return ysdk
                                    .getPlayer({ scopes: true, signed: true })
                                    .then((player) => {
                                        this.props.actions
                                            .authYandexGame({
                                                id: player.getUniqueID(),
                                                name: player.getName(),
                                                photo: player.getPhoto('large'),
                                                signature: player.signature,
                                            })
                                            .then(
                                                () => {
                                                    if (ysdk.features && ysdk.features.LoadingAPI) {
                                                        ysdk.features.LoadingAPI.ready();
                                                    }

                                                    resolve();
                                                },
                                                () => {
                                                    reject(
                                                        new Error('Can not auth user on server')
                                                    );
                                                }
                                            );
                                    });
                            };

                            const startAuth = () => {
                                ysdk.auth
                                    .openAuthDialog()
                                    .then(() => {
                                        initPlayer();
                                    })
                                    .catch(() => {
                                        // Игрок не авторизован.
                                        startAuth();
                                        // Recursion
                                    });
                            };

                            initPlayer()
                                .then(() => {
                                    //
                                })
                                .catch(() => {
                                    startAuth();
                                });

                            ysdk.getPayments({ signed: true })
                                .then(() => {
                                    this.props.actions.setAllowBuyMoney(true);
                                })
                                .catch((err) => {
                                    console.error(err);
                                });
                        });
                    });

                    break;
                }
                default:
            }
        });

        initResult
            .then(() => {
                // Do something
                this.tryToShowAdv();

                setTimeout(() => {
                    this.processUncompletedPayments();
                }, 300);
            })
            .catch((error) => {
                console.log(error);
                document.title = String(error);

                if (this.props.appType === TYPE_YANDEX) {
                    // eslint-disable-next-line no-alert
                    alert(error);
                }
            });
    }

    processUncompletedPayments() {
        if (this.props.appType === TYPE_YANDEX) {
            window.YaGames.init().then((ysdk) => {
                ysdk.getPayments({ signed: true })
                    .then((payments) => {
                        // Process unprocessed payments
                        payments.getPurchases().then((purchases) => {
                            const tokens = purchases.map((entry) => entry.purchaseToken);

                            if (purchases.signature && tokens.length) {
                                this.props.actions.submitYandexPayment(
                                    purchases.signature,
                                    (response) => {
                                        if (
                                            response.processedTokens &&
                                            Array.isArray(response.processedTokens)
                                        ) {
                                            response.processedTokens.forEach((purchaseToken) => {
                                                payments.consumePurchase(purchaseToken);
                                            });
                                        }
                                    }
                                );
                            }
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    }

    tryToShowAdv() {
        const { user, isAllowSeeAdv } = this.props;

        const startAdv = () => {
            this.props.actions.showAdv(ADV_PRELOADER, user.externalId).catch((error) => {
                console.log(error);
            });
        };

        const loadAdvLib = () => {
            if (this.props.appType === TYPE_VK) {
                return Promise.all([
                    loadScript(VK_ADV_LIBRARY_ADMAN),
                    loadScript(VK_ADV_LIBRARY_ADMAN_INIT),
                ]);
            } else {
                return Promise.resolve();
            }
        };

        if (isAllowSeeAdv) {
            loadAdvLib().then(() => {
                if (
                    user.level > MIN_LEVEL_TO_SEE_ADV &&
                    randomIntFromInterval(0, 100) < SEE_ADV_PERCENT
                ) {
                    startAdv();
                }
            });
        }
    }
}

App.propTypes = {
    isVkIos: PropTypes.bool,
    isVkAndroid: PropTypes.bool,
    queryAppType: PropTypes.string,
    openUrl: PropTypes.string,
};

const mapStateToProps = function (state) {
    return {
        isReady: state.app.isReady,
        appType: state.app.appType,
        session: state.app.session,
        isAllowSeeAdv: state.app.isAllowSeeAdv,
        user: state.profile.info,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setAppType,
                authVkGame,
                authVkApp,
                authFbApp,
                auth,
                fetchProfile,
                setAllowBuyMoney,
                setAllowSeeAdv,
                showAdv,
                authYandexGame,
                submitYandexPayment,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
