import axios from 'axios';
import { SERVER_API, SESSION_STORAGE_KEY } from '../../config';

import I18n from '../../i18n';
import UserModel from '../../model/user';
import { userAuthorization } from '../app';
import Storage from '../../utils/storage';
import { uploadPhoto } from '../profile';

export const AUTH_STEP = 'auth';
export const REGISTRATION_STEP = 'registration';

const SET_EMAIL = Symbol('SET_EMAIL');

export function setEmail(email) {
    return {
        type: SET_EMAIL,
        email,
    };
}

const SET_PASSWORD = Symbol('SET_PASSWORD');

export function setPassword(password) {
    return {
        type: SET_PASSWORD,
        password,
    };
}

const SET_FIRST_NAME = Symbol('SET_FIRST_NAME');

export function setFirstName(firstName) {
    return {
        type: SET_FIRST_NAME,
        firstName,
    };
}

const SET_LAST_NAME = Symbol('SET_LAST_NAME');

export function setLastName(lastName) {
    return {
        type: SET_LAST_NAME,
        lastName,
    };
}

const SET_IS_LOADING = Symbol('SET_IS_LOADING');

function setIsLoading(isLoading = false) {
    return {
        type: SET_IS_LOADING,
        isLoading,
    };
}

const SET_STEP = Symbol('SET_STEP');

export function setStep(step = AUTH_STEP) {
    return {
        type: SET_STEP,
        step,
    };
}

const CLEAR = Symbol('CLEAR');

export function clear() {
    return {
        type: CLEAR,
    };
}

export function sendJoin({ email, password, onNewUser, authType, authData }) {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        return axios
            .post(`${SERVER_API}/join`, {
                email,
                password,
                authType,
                authData,
            })
            .then(function (response) {
                if (response.data.isNewUser) {
                    if (onNewUser) {
                        onNewUser();
                    } else {
                        dispatch(setStep(REGISTRATION_STEP));
                    }
                } else if (response.data.session) {
                    // User authorized
                    Storage.setItem(SESSION_STORAGE_KEY, response.data.session).then(() => {
                        const user = new UserModel(response.data.user);
                        dispatch(userAuthorization(response.data.session, user));
                    });
                }
            })
            .catch((error) => {
                let message;

                if (error.response.data.error) {
                    message = error.response.data.error;
                } else if (error.response.data.email) {
                    message = error.response.data.email[0];
                }

                alert(I18n.t('error'), message);
            })
            .then(() => {
                dispatch(setIsLoading(false));
            });
    };
}

export function sendRegistration({
    email,
    password,
    firstName,
    lastName,
    photoBase64,
    authType,
    authData,
}) {
    return function (dispatch) {
        dispatch(setIsLoading(true));

        return axios
            .post(`${SERVER_API}/register`, {
                email,
                password,
                firstName,
                lastName,
                authData,
                authType,
            })
            .then(function (response) {
                if (response.data.session) {
                    // Upload photo first with new session. Waiting loading
                    dispatch(uploadPhoto(photoBase64, response.data.session)).then((userData) => {
                        // User authorized
                        Storage.setItem(SESSION_STORAGE_KEY, response.data.session).then(() => {
                            const user = new UserModel(userData);
                            dispatch(userAuthorization(response.data.session, user));
                            dispatch(setIsLoading(false));
                        });
                    });
                }
            })
            .catch((error) => {
                let message;

                if (error.response.data.error) {
                    message = error.response.data.error;
                }

                if (message) {
                    alert(I18n.t('error'), message);
                }

                dispatch(setIsLoading(false));
            });
    };
}

const initialState = {
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    isLoading: false,
    step: AUTH_STEP,
    error: null,
};

export default function appReducer(state = initialState, action) {
    const exec = {};

    exec[SET_EMAIL] = function () {
        return {
            ...state,
            email: action.email,
        };
    };

    exec[SET_PASSWORD] = function () {
        return {
            ...state,
            password: action.password,
        };
    };

    exec[SET_FIRST_NAME] = function () {
        return {
            ...state,
            firstName: action.firstName,
        };
    };

    exec[SET_LAST_NAME] = function () {
        return {
            ...state,
            lastName: action.lastName,
        };
    };

    exec[SET_IS_LOADING] = function () {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    };

    exec[SET_STEP] = function () {
        return {
            ...state,
            step: action.step,
        };
    };

    exec[CLEAR] = function () {
        return initialState;
    };

    if (exec[action.type]) {
        return exec[action.type].call();
    }

    return state;
}
