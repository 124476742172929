import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FriendGame from './Friend';
import RandomGame from './Random';
import Play from './Play';

import { GAME_TYPE_FRIEND, GAME_TYPE_RANDOM, GAME_TYPE_SINGLE } from '../../../config';

import {
    EVENT_CLIENT_START_NEW_GAME,
    EVENT_SERVER_GAME_STARTED,
    EVENT_CLIENT_LEAVE,
    EVENT_SERVER_GAME_FINISHED,
    EVENT_SERVER_GAME_DISCONNECTED,
    EVENT_SERVER_GAME_CREATED,
    EVENT_CLIENT_START_GAME_WITH_BOT,
} from '../../../config/socket';

import {
    setIsGameStarted,
    setTimerValue,
    setPlayUser,
    setActiveUserId,
    setRoundTimeout,
    setHelpers,
    setFinishGame,
} from '../../../store/game';
import { fetchProfile } from '../../../store/profile';
import { showDialog } from '../../../store/dialog';
import { DIALOG_MULTIPLAYER_GAME_FINISH } from '../../Dialog/Types/MultiplayerGameFinish';
import { DIALOG_USER_DISCONNECTED } from '../../Dialog/Types/UserDisconnected';
import GameModel from '../../../model/game';
import RoundModel from '../../../model/round';
import GameHelperModel from '../../../model/gameHelper';
import PlayUser from '../../../model/playUser';
import { URL_HOME } from '../../../config/url';
import { goToPage, STATE_BACKGROUND, TYPE_YANDEX } from '../../../store/app';
import {
    sendReachGoal,
    EVENT_START_GAME,
    EVENT_START_FRIEND_GAME,
    EVENT_START_SINGLE,
} from '../../../store/statistic';

const WAITING_PLAYER_TIME = 10000;

class PageGame extends PureComponent {
    static navigationOptions = () => {
        return {
            header: null,
            waitingRoomId: null, // for game with bot
        };
    };

    constructor(props) {
        super(props);

        this.handleStartGame = this.handleStartGame.bind(this);
        this.onGameStarted = this.onGameStarted.bind(this);
        this.onGameFinished = this.onGameFinished.bind(this);
        this.onUserLeftGame = this.onUserLeftGame.bind(this);
        this.onGameCreated = this.onGameCreated.bind(this);
        this.handleRequestNewGame = this.handleRequestNewGame.bind(this);

        this._gameBotTimer = null;
        this._fetchProfileTimer = null;

        this.state = {
            waitingRoomId: null,
        };
    }

    componentDidMount() {
        this.props.socket.on(EVENT_SERVER_GAME_STARTED, this.onGameStarted);
        this.props.socket.on(EVENT_SERVER_GAME_FINISHED, this.onGameFinished);
        this.props.socket.on(EVENT_SERVER_GAME_DISCONNECTED, this.onUserLeftGame);
        this.props.socket.on(EVENT_SERVER_GAME_CREATED, this.onGameCreated);
    }

    componentWillUnmount() {
        clearTimeout(this._gameBotTimer);
        clearTimeout(this._fetchProfileTimer);

        this.props.socket.off(EVENT_SERVER_GAME_STARTED, this.onGameStarted);
        this.props.socket.off(EVENT_SERVER_GAME_FINISHED, this.onGameFinished);
        this.props.socket.off(EVENT_SERVER_GAME_DISCONNECTED, this.onUserLeftGame);
        this.props.socket.off(EVENT_SERVER_GAME_CREATED, this.onGameCreated);

        this.props.actions.setIsGameStarted(false);

        this.leaveFromGame();
    }

    componentDidUpdate() {
        if (this.props.appState === STATE_BACKGROUND) {
            this.props.actions.goToPage(URL_HOME);
        }
    }

    getGameType() {
        if (this.props.match) {
            return this.props.match.params.type;
        } else if (this.props.navigation) {
            return this.props.navigation.getParam('type');
        }
    }

    getGamePin() {
        if (this.props.match) {
            return this.props.match.params.pin;
        } else if (this.props.navigation) {
            return this.props.navigation.getParam('pin');
        }
    }

    leaveFromGame() {
        this.props.socket.emit(EVENT_CLIENT_LEAVE);
    }

    render() {
        if (this.props.isGameStarted) {
            return <Play />;
        } else {
            switch (this.getGameType()) {
                case GAME_TYPE_FRIEND:
                    return (
                        <FriendGame
                            pin={this.getGamePin()}
                            onStart={this.handleStartGame}
                            navigation={this.props.navigation}
                        />
                    );
                default:
                    return (
                        <RandomGame
                            onStart={this.handleStartGame}
                            navigation={this.props.navigation}
                        />
                    );
            }
        }
    }

    handleStartGame(gameType, pin, gameId) {
        if (!pin) {
            this.setBotFindTimer();
        }

        this.props.socket.emit(EVENT_CLIENT_START_NEW_GAME, {
            userId: this.props.user.id,
            gameType,
            pin,
            gameId,
        });

        switch (gameType) {
            case GAME_TYPE_FRIEND:
                this.props.actions.sendReachGoal(EVENT_START_FRIEND_GAME);
                break;
            case GAME_TYPE_RANDOM:
                this.props.actions.sendReachGoal(EVENT_START_GAME);
                break;
            case GAME_TYPE_SINGLE:
                this.props.actions.sendReachGoal(EVENT_START_SINGLE);
                break;
            default:
        }

        /*this.props.actions.showDialog(DIALOG_MULTIPLAYER_GAME_FINISH, {
            isPlayerWinner: false,
            winUser: {
                firstName: 'Вячеслав',
                photoUrl: 'https://memoton.witgames.info/public/users/vk_5e9f8645f1ca2.jpg',
            },
            earnPoints: 1000,
            onNewGame: this.handleStartGame,
        });*/
    }

    handleRequestNewGame() {
        // Finsh game and render blocks again
        this.props.actions.setFinishGame(false);
    }

    onGameCreated({ roomId }) {
        this.setState({
            waitingRoomId: roomId,
        });
    }

    /**
     * All rounds completed.
     *
     * @param response
     * @param response.firstUserId
     * @param response.secondUserId
     * @param response.winUserId
     * @param response.firstUserPoints
     * @param response.secondUserPoints
     */
    onGameFinished({ winUserId, firstUserId, firstUserPoints, secondUserPoints }) {
        const { usersStatistic, user } = this.props;

        let winUser;
        let earnPoints;
        let isPlayerWinner = false;

        if (Number(firstUserId) === Number(user.id)) {
            earnPoints = firstUserPoints;
        } else {
            earnPoints = secondUserPoints;
        }

        if (winUserId) {
            winUser = usersStatistic[winUserId];

            if (Number(winUserId) === Number(user.id)) {
                isPlayerWinner = true;
            }
        }

        this.props.actions.showDialog(DIALOG_MULTIPLAYER_GAME_FINISH, {
            isPlayerWinner,
            winUser,
            earnPoints,
            onNewGame: this.handleRequestNewGame,
        });

        this._fetchProfileTimer = setTimeout(() => {
            this.props.actions.fetchProfile((user) => {
                if (this.props.appType === TYPE_YANDEX) {
                    window.YaGames.init().then((ysdk) => {
                        ysdk.getLeaderboards().then((lb) => {
                            lb.setLeaderboardScore('topgamers', user.totalPoints);
                        });
                    });
                }
            });
        }, 300);
    }

    /**
     *
     * @param data
     * @param data.existedUserId
     * @param data.disconnectedUserId
     */
    onUserLeftGame() {
        this.props.actions.showDialog(DIALOG_USER_DISCONNECTED, {
            onNewGame: this.handleRequestNewGame,
        });
    }

    onGameStarted({
        roundTimeout,
        firstUser,
        secondUser,
        firstUserId,
        secondUserId,
        // question,
        game,
        type,
        round,
    }) {
        clearTimeout(this._gameBotTimer);

        game.type = type;

        const gameModel = new GameModel(game);
        const roundModel = new RoundModel(round);
        const user = this.props.user;

        this.props.actions.setPlayUser(firstUserId, new PlayUser(firstUser.user));
        this.props.actions.setPlayUser(secondUserId, new PlayUser(secondUser.user));

        this.props.actions.setTimerValue(roundTimeout);
        this.props.actions.setRoundTimeout(roundTimeout);

        let userHelpers;

        if (Number(firstUserId) === Number(user.id)) {
            userHelpers = firstUser.helpers;
        } else {
            userHelpers = secondUser.helpers;
        }

        const helpers = userHelpers.map((helper) => new GameHelperModel(helper));

        this.props.actions.setHelpers(helpers);
        this.props.actions.setIsGameStarted(true, gameModel, roundModel);

        this.setState({
            waitingRoomId: null,
        });
    }

    setBotFindTimer() {
        this._gameBotTimer = setTimeout(() => {
            // Start game with bot
            console.log('No users found. Start game with bot');

            this.props.socket.emit(EVENT_CLIENT_START_GAME_WITH_BOT, {
                userId: this.props.user.id,
                roomId: this.state.waitingRoomId,
            });

            clearTimeout(this._gameBotTimer);
        }, WAITING_PLAYER_TIME);
    }
}

const mapStateToProps = function (state) {
    return {
        appState: state.app.appState,
        user: state.profile.info,
        isGameStarted: state.game.isGameStarted,
        socket: state.app.socket,
        game: state.game.game,
        usersStatistic: state.game.usersStatistic,
        appType: state.app.appType,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        actions: bindActionCreators(
            {
                setIsGameStarted,
                setTimerValue,
                showDialog,
                setPlayUser,
                setActiveUserId,
                goToPage,
                setRoundTimeout,
                setHelpers,
                setFinishGame,
                sendReachGoal,
                fetchProfile,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageGame);
