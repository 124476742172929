export const IS_PRODUCTION =
    process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_PRODUCTION;
export const IS_FORCED_PRODUCTION = process.env.REACT_APP_FORCE_PRODUCTION;

export const FILTER_DAY = 'day';
export const FILTER_WEEK = 'week';
export const FILTER_MONTH = 'month';
export const FILTER_TOTAL = 'total';
export const FILTER_AUTHORS = 'authors';

export const GAME_TYPE_RANDOM = 'random';
export const GAME_TYPE_FRIEND = 'friend';
export const GAME_TYPE_SINGLE = 'single';

export const TEST_SERVER_API = 'http://local.witgames.info/umnik';
export const PROD_SERVER_API = 'https://api.witgames.info/umnik';

const TEST_SOCKET = 'http://local.witgames.info:8091';
const PROD_SOCKET = 'https://umnikgame.ru';

export const MAIN_DOMAIN = 'umnikgame.ru';

export const SERVER_API = IS_PRODUCTION ? PROD_SERVER_API : TEST_SERVER_API;
export const SERVER_SOCKET_HOST = IS_PRODUCTION ? PROD_SOCKET : TEST_SOCKET;
export const SERVER_SOCKET_IS_SECURE = !!SERVER_SOCKET_HOST.match('https');

export const IOS_GAME_LINK =
    'https://itunes.apple.com/ru/app/%D1%83%D0%BC%D0%BD%D0%B8%D0%BA/id1338518047';
export const ANDROID_GAME_LINK =
    'https://play.google.com/store/apps/details?id=ru.vexell.umnikgame';

export const SESSION_STORAGE_KEY = 'session';
export const RUSSIAN_LOCALES = ['ru', 'uk', 'kk', 'be'];
export const PRIVACY_POLICY_URL = 'https://witgames.info/privacy';

export const ANIMATION_SCREEN_TIME = 350;
export const YA_METRIKA_ID = 47642014;

export const MIN_LEVEL_TO_RATE_QUESTION = 5;

export const ADV_INTERSTITIAL = 'interstitial';
export const ADV_REWARD = 'reward';
export const ADV_PRELOADER = 'preloader';
export const SEE_ADV_PERCENT = 25;
export const MIN_LEVEL_TO_SEE_ADV = 2;
export const LS_MONEY_TIP_KEY = 'MN_TOOLTIP_TIME';

export const VK_ADV_LIBRARY_ADMAN = 'https://ad.mail.ru/static/admanhtml/rbadman-html5.min.js';
export const VK_ADV_LIBRARY_ADMAN_INIT = 'https://vk.com/js/api/adman_init.js';
export const YANDEX_LIB = 'https://yandex.ru/games/sdk/v2';
